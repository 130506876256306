var recaptchaLoaded = false;
var recaptchaKey = '6LflaqYaAAAAADzPqeMos6XzoOlK8ZpjIDuQLKo-';
$(".modal").on('shown.bs.modal', function () {
  var form = $(this);
  initLyforms(form);
  window.location.hash = 'form=' + form.attr("id") + '&step=1';
  window.addEventListener('message', lyformsEventMessage);
});

function initRecaptcha() {
  if (!recaptchaLoaded) {
    recaptchaLoaded = true;
    var head = document.getElementsByTagName('head')[0];
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://www.google.com/recaptcha/api.js?render=' + recaptchaKey;
    head.appendChild(script);
  }
}

function initLyforms(form) {
  if (form.find(".lyformsContainer").html() == '') {
    var iframeHtml = '<iframe class="lyforms lyformsSurvey" src="https://royalbled.lyforms.com/tenders/publicFormFramed/J22KWQDP/iframe_id/lyforms_J22KWQDP/responsive/1/set_locale/' + $("html").attr("lang") + '" style="width:100%;" frameborder="0" scrolling="no"></iframe>';
    form.find(".lyformsContainer").append(iframeHtml);
  }
}

$(".modal").on('hidden.bs.modal', function () {
  history.pushState("", document.title, window.location.pathname);
  window.removeEventListener('message', lyformsEventMessage);
});

function lyformsEventMessage(e) {
  var data = e.data;
  if (data.type === 'formData') {
    $(".inquiryModal.show").modal("hide");
  }
}

if (window.location.hash) {
  if (window.location.hash.startsWith("#form=")) {
    var hashValue = window.location.hash.replace("#form=", "").split("&");
    $("#" + hashValue[0]).modal("show");
  }
}

var formContainer = $('.formmodal');

formContainer.each(function () {

  var form = $(this).show();

  var stepsNumber = form.find("section").length;
  form.steps({
    headerTag: "h3",
    bodyTag: "section",
    transitionEffect: "fade",
    transitionEffectSpeed: 350,
    saveState: false,
    autoFocus: false,
    labels: {
      cancel: $(this).data('cancel-txt'),
      finish: $(this).data('submit-txt'),
      next: $(this).data('next-txt'),
      previous: $(this).data('previous-txt')
    },

    onInit: function (event, current) {
      form.find('.actions > ul > li:first-child').attr('style', 'display:none');
      if (stepsNumber == 2) {
        form.find('.actions > ul > li a[href$="next"]').text(form.data('submit-txt'));
      }
    },

    onStepChanging: function (event, currentIndex, newIndex) {
      $(".modal-content").animate({ scrollTop: 0 }, "slow");
      if (currentIndex > newIndex) {
        return true;
      }
      if (currentIndex < newIndex) {
        form.find(".body:eq(" + newIndex + ") label.error").remove();
        form.find(".body:eq(" + newIndex + ") .error").removeClass("error");
      }
      form.validate().settings.ignore = ":disabled,:hidden";
      return form.valid();
    },
    onStepChanged: function (event, currentIndex, priorIndex) {
      var myUrl = new URL(window.location.href);
      var hash = new URLSearchParams(myUrl.hash.substring(1));
      var stepValue = hash.get('step');
      var newHash = window.location.hash.replace("step=" + stepValue, "step=" + (currentIndex + 1));
      window.location.hash = newHash;

      if (currentIndex == 1) {
        // subscribeToMailee(form);
      }
      if ((stepsNumber == 3 && currentIndex == 2) || (stepsNumber == 2 && currentIndex == 1)) {
        form.find('.actions > ul').attr('style', 'display:none');
        initRecaptcha();

        var interval = setInterval(function () {
          // do your loop
          if (typeof grecaptcha !== 'undefined') {
            clearInterval(interval);
            grecaptcha.ready(function () {
              grecaptcha.execute(recaptchaKey, { action: 'validate_captcha' }).then(function (token) {
                form.find(".recaptchaResponse").val(token);
                sendInquiry(form);
              });
            });
          }
        }, 200);
        // TODO: If necessary check if grecaptcha still not defined and display a warning to user or send an email to Dspot for information
        // sendInquiry(form);
      } else if (stepsNumber == 3 && currentIndex == 1) {
        form.find('.actions > ul > li a[href$="next"]').text(form.data('inquiry-txt'));

        form.find(".datepicker").each(function () {
          // Function to round time to nearest 15 minutes
          var interval = $(this).data("interval");
          var intervalHalf = (interval / 2) + 1;
          function roundToNearestMinutes(date, interval) {
            var minutes = date.getMinutes();
            var remainder = minutes % interval;
            $('.numInputWrapper label.error').hide();
            return new Date(date.getTime() + (remainder >= intervalHalf ? (interval - remainder) : -remainder) * 60000);
          }

          var pickerParams = {
            //minDate: new Date().fp_incr(1),
            minDate: "2025-04-01",
            /*
            disable: [
              {
                from: "2024-03-01",
                to: "2024-03-27"
              }
            ],*/
            plugins: [
              new confirmDatePlugin({})
            ],
            dateFormat: 'j. n. Y',
            disableMobile: "true",
            locale: $("html").attr("lang"),
            static: true
          };

          if ($(this).data("mintime") || $(this).data("maxtime")) {
            pickerParams.enableTime = true;
            pickerParams.time_24hr = true;
            pickerParams.dateFormat = 'j. n. Y H:i';
            pickerParams.onValueUpdate = function (selectedDates, dateStr, instance) {
              // Round the selected date to the nearest 15 minutes when manually inputted
              var roundedDate = roundToNearestMinutes(selectedDates[0], interval);
              instance.setDate(roundedDate);
            };

            if ($(this).data("mintime")) { pickerParams.minTime = $(this).data("mintime"); }
            if ($(this).data("maxtime")) { pickerParams.maxTime = $(this).data("maxtime"); }
            if ($(this).data("interval")) { pickerParams.minuteIncrement = $(this).data("interval"); }
          }

          if ($(this).hasClass("departure")) {
            pickerParams.minDate = new Date().fp_incr(1);
          }

          $(this).flatpickr(pickerParams);
          if ($(this).hasClass("arrival")) {
            var departurePicker = form.find("input.datepicker.departure");
            $(this).on("change", function () {
              if (departurePicker.length > 0) {
                var arrivalDate = $(this).val().split(". ");
                var currentDate = new Date(arrivalDate[2], arrivalDate[1] - 1, arrivalDate[0]);
                var nextDay = new Date(currentDate.setDate(currentDate.getDate() + 1));

                var departureDate = departurePicker.val().split(". ");
                departureDate = new Date(departureDate[2], departureDate[1] - 1, departureDate[0]);

                var departureValue = departureDate.getTime();
                if (departureValue < nextDay.getTime()) {
                  pickerParams.defaultDate = nextDay;
                }
                pickerParams.minDate = nextDay;
                departurePicker.flatpickr(pickerParams);
              }
            });
          }
        });

        form.find('.js-example-basic-single').select2({
          minimumResultsForSearch: -1,
          placeholder: 'Select an option'
        });
      } else if (currentIndex == 0) {
        form.find('.actions > ul > li a[href$="next"]').text($(this).data('next-txt'));
      }
      if (currentIndex > 0 && ((stepsNumber == 3 && currentIndex != 2) || (stepsNumber == 2 && currentIndex != 1))) {
        form.find('.actions > ul > li:first-child').attr('style', '');
      } else {
        form.find('.actions > ul > li:first-child').attr('style', 'display:none');
      }
    },
    onFinishing: function (event, currentIndex) {
      form.validate().settings.ignore = ":disabled";
      return form.valid();
    },
    onFinished: function (event, currentIndex) {
      sendAdditional();
    }
  }).validate({
    errorPlacement: function errorPlacement(error, element) {
      if (element.is(":checkbox")) {
        element.closest('.option-terms').before(error);
      } else {
        element.before(error);
      }
    },
    rules: {
      name: {
        required: true
      },
      email: {
        required: true
      },
      phoneNumber: {
        pattern: /^\+[1-9]\d{1,14}$/
      }
    },
    messages: {
      phoneNumber: {
        required: form.find('input[name=phoneNumber]').data('required'),
        pattern: form.find('input[name=phoneNumber]').data('pattern'),
      },
      name: {
        required: form.find('input[name=name]').data('required')
      },
      email: {
        required: form.find('input[name=email]').data('required')
      },
      acceptTerms: {
        required: form.find('input[name=acceptTerms]').data('required')
      },
      "data[Company]": {
        required: form.find('input[name=data\\[Company\\]]').data('required')
      },
      "data[Duration]": {
        required: form.find('input[name=data\\[Duration\\]]').data('required')
      },
      "data[Persons]": {
        required: form.find('input[name=data\\[Persons\\]]').data('required')
      },
      // step: {
      //   required: form.find('input[name=data\\[Date\\]]').data('step')
      // },
      "data[Date]": {
        required: form.find('input[name=data\\[Date\\]]').data('required')
      },
      "data[Date of arrival]": {
        required: form.find('input[name=data\\[Date\\ of\\ arrival\\]]').data('required')
      },
      "data[Date of departure]": {
        required: form.find('input[name=data\\[Date\\ of\\ departure\\]]').data('required')
      },
      "data[Gift card value]": {
        required: form.find('input[name=data\\[Gift\\ card\\ value\\]]').data('required')
      },
      "data[Text on the gift card]": {
        required: form.find('input[name=data\\[Text\\ on\\ the\\ gift\\ card\\]]').data('required')
      },
      "data[Free delivery address]": {
        required: form.find('input[name=data\\[Free\\ delivery\\ address\\]]').data('required')
      },
      "data[Free delivery post code]": {
        required: form.find('input[name=data\\[Free\\ delivery\\ post\\ code\\]]').data('required')
      },
      "data[Free delivery post]": {
        required: form.find('input[name=data\\[Free\\ delivery\\ post\\]]').data('required')
      },
      "data[Free delivery different Address]": {
        required: form.find('input[name=data\\[Free\\ delivery\\ different\\ Address\\]]').data('required')
      },
      "data[Free delivery different Post code]": {
        required: form.find('input[name=data\\[Free\\ delivery\\ different\\ Post\\ code\\]]').data('required')
      },
      "data[Free delivery different Post]": {
        required: form.find('input[name=data\\[Free\\ delivery\\ different\\ Post\\]]').data('required')
      },
      "data[Company address]": {
        required: form.find('input[name=data\\[Company\\ address\\]]').data('required')
      },
      "data[Company post code]": {
        required: form.find('input[name=data\\[Company\\ post\\ code\\]]').data('required')
      },
      "data[Company post]": {
        required: form.find('input[name=data\\[Company\\ post\\]]').data('required')
      },
      "data[Company different Address - Address]": {
        required: form.find('input[name=data\\[Company\\ different\\ address\\ -\\ Address\\]]').data('required')
      },
      "data[Company different Address - Post code]": {
        required: form.find('input[name=data\\[Company\\ different\\ address\\ -\\ Post\\ code\\]]').data('required')
      },
      "data[Company different Address - Post]": {
        required: form.find('input[name=data\\[Company\\ different\\ address\\ -\\ Post\\]]').data('required')
      },
    }
  });
});

/**
 * Gift card
 */
$('.radios-billing').on('change', function () {
  if ($("#billing-person").is(':checked')) {
    $("#form-person").removeClass("hidden");
    $("#form-person").addClass("show");
    $("#form-company").removeClass("show");
    $("#form-company").addClass("hidden");

    //enable person billing
    $('#invoice-address').prop('required', true);
    $('#invoice-address').prop('disabled', false);
    $('#invoice-post-code').prop('required', true);
    $('#invoice-post-code').prop('disabled', false);
    $('#invoice-post').prop('required', true);
    $('#invoice-post').prop('disabled', false);
    $('#select-list').prop('disabled', true);

    //enable person delivery method
    $('#person-free-delivery').prop('disabled', false);
    $('#person-personal-pickup').prop('disabled', false);
    $('#delivery-above-address').prop('disabled', false);
    $('#delivery-different-address').prop('disabled', true);

    //disable company delivery method
    $('#free-delivery-company').prop('disabled', true);
    $('#personal-pickup-company').prop('disabled', true);

    //disable company info
    $('#invoice-company-name').prop('disabled', true);
    $('#company-taxable-yes').prop('disabled', true);
    $('#company-taxable-no').prop('disabled', true);
    $('#invoice-address-company').prop('disabled', true).prop('required', false);
    $('#invoice-company-post-code').prop('disabled', true).prop('required', false);
    $('#invoice-company-post').prop('disabled', true).prop('required', false);
    $('#company-delivery-above-address').prop('disabled', true);
    $('#company-delivery-different-address').prop('disabled', true);
    $('#select-list-company-country').prop('disabled', true);

  } else if ($("#billing-company").is(':checked')) {
    $("#form-company").removeClass("hidden");
    $("#form-company").addClass("show");
    $("#form-person").removeClass("show");
    $("#form-person").addClass("hidden");

    //disable person billing
    $('#invoice-address').prop('required', false);
    $('#invoice-address').prop('disabled', true);
    $('#invoice-post-code').prop('required', false);
    $('#invoice-post-code').prop('disabled', true);
    $('#invoice-post').prop('required', false);
    $('#invoice-post').prop('disabled', true);
    $('#select-list').prop('disabled', true);

    // disable person delivery method
    $('#person-free-delivery').prop('disabled', true);
    $('#person-personal-pickup').prop('disabled', true);
    $('#delivery-above-address').prop('disabled', true);
    $('#delivery-different-address').prop('disabled', true);

    //enable company delivery method
    $('#free-delivery-company').prop('disabled', false);
    $('#personal-pickup-company').prop('disabled', false);

    //enable company info
    $('#invoice-company-name').prop('disabled', false);
    $('#company-taxable-yes').prop('disabled', false);
    $('#company-taxable-no').prop('disabled', false);
    $('#invoice-address-company').prop('disabled', false).prop('required', true);
    $('#invoice-company-post-code').prop('disabled', false).prop('required', true);
    $('#invoice-company-post').prop('disabled', false).prop('required', true);
    $('#company-delivery-above-address').prop('disabled', false);
    $('#company-delivery-different-address').prop('disabled', false);
    $('#select-list-company-country').prop('disabled', false);
  }
});

$('.radio-delivery').on('change', function () {
  if ($("#delivery-above-address").is(':checked')) {
    $('.different-address-person').addClass('hidden');
    $('.different-address-person').removeClass('show');

    //Delivery different address person
    $('#different-invoice-address').prop('disabled', true);
    $('#different-invoice-address').prop('required', false);
    $('#different-invoice-post-code').prop('disabled', true);
    $('#different-invoice-post-code').prop('required', false);
    $('#different-invoice-post').prop('disabled', true);
    $('#different-invoice-post').prop('required', false);
    $('#select-list-person-different-address').prop('disabled', true);

  } else if ($("#delivery-different-address").is(':checked')) {
    $('.different-address-person').removeClass('hidden');
    $('.different-address-person').addClass('show');

    //Delivery different address person
    $('#different-invoice-address').prop('disabled', false);
    $('#different-invoice-address').prop('required', true);
    $('#different-invoice-post-code').prop('disabled', false);
    $('#different-invoice-post-code').prop('required', true);
    $('#different-invoice-post').prop('disabled', false);
    $('#different-invoice-post').prop('required', true);
    $('#select-list-person-different-address').prop('disabled', false);
  }
});

$('.radio-delivery-company').on('change', function () {
  if ($("#company-delivery-above-address").is(':checked')) {
    $('.different-address-company').addClass('hidden');
    $('.different-address-company').removeClass('show');

    //disable company delivery to different address
    $('#company-different-invoice-name').prop('disabled', true).prop('required', false);
    $('#company-different-invoice-address').prop('disabled', true).prop('required', false);
    $('#company-different-invoice-post-code').prop('disabled', true).prop('required', false);
    $('#company-different-invoice-post').prop('disabled', true).prop('required', false);
    $('#select-list-company-country-different').prop('disabled', true);

  } else if ($("#company-delivery-different-address").is(':checked')) {
    $('.different-address-company').removeClass('hidden');
    $('.different-address-company').addClass('show');

    //enable company delivery to different address
    $('#company-different-invoice-name').prop('disabled', false).prop('required', true);
    $('#company-different-invoice-address').prop('disabled', false).prop('required', true);
    $('#company-different-invoice-post-code').prop('disabled', false).prop('required', true);
    $('#company-different-invoice-post').prop('disabled', false).prop('required', true);
    $('#select-list-company-country-different').prop('disabled', false).prop('required', true);
  }
});

$('.delivery-person-radios').on('change', function () {
  if ($("#person-free-delivery").is(':checked')) {
    $('.person-personal-pickup-option').addClass('hidden');
    $('.person-personal-pickup-option').removeClass('show');
    $('.person-free-delivery-option').removeClass('hidden');
    $('.person-free-delivery-option').addClass('show');
    $('.payment-method-two').removeClass('show');
    $('.payment-method-two').addClass('hidden');
    $('.payment-method-two input').prop('disabled', true);
    $('.payment-method-two input').prop('checked', false);
    $('.payment-method-one input').prop('checked', true);

    //delivery to address enabeled
    $('#delivery-above-address').prop('disabled', false);
    $('#delivery-different-address').prop('disabled', false);

    //primary address enabeled
    $('#invoice-address').prop('disabled', false);
    $('#invoice-address').prop('required', true);
    $('#invoice-post-code').prop('disabled', false);
    $('#invoice-post-code').prop('required', true);
    $('#invoice-post').prop('disabled', false);
    $('#invoice-post').prop('required', true);


  } else if ($("#person-personal-pickup").is(':checked')) {
    $('.person-free-delivery-option').removeClass('show');
    $('.person-free-delivery-option').addClass('hidden');
    $('.person-personal-pickup-option').addClass('show');
    $('.person-personal-pickup-option').removeClass('hidden');
    $('.payment-method-two').removeClass('hidden');
    $('.payment-method-two').addClass('show');
    $('.payment-method-two input').prop('disabled', false);

    //delivery to address disabeled
    $('#delivery-above-address').prop('disabled', true);
    $('#delivery-different-address').prop('disabled', true);

    //primary address disabled
    $('#invoice-address').prop('disabled', true);
    $('#invoice-address').prop('required', false);
    $('#invoice-post-code').prop('disabled', true);
    $('#invoice-post-code').prop('required', false);
    $('#invoice-post').prop('disabled', true);
    $('#invoice-post').prop('required', false);

  }
});

$('.delivery-company-radios').on('change', function () {
  if ($("#free-delivery-company").is(':checked')) {
    $('.company-free-delivery-option').removeClass('hidden');
    $('.company-free-delivery-option').addClass('show');
    $('.company-personal-pickup-option').removeClass('show');
    $('.company-personal-pickup-option').addClass('hidden');
    $('.payment-method-two').removeClass('show');
    $('.payment-method-two').addClass('hidden');
    $('.payment-method-two input').prop('disabled', true);
    $('.payment-method-two input').prop('checked', false);
    $('.payment-method-one input').prop('checked', true);
  } else if ($("#personal-pickup-company").is(':checked')) {
    $('.company-free-delivery-option').removeClass('show');
    $('.company-free-delivery-option').addClass('hidden');
    $('.company-personal-pickup-option').removeClass('hidden');
    $('.company-personal-pickup-option').addClass('show');
    $('.payment-method-two').removeClass('hidden');
    $('.payment-method-two').addClass('show');
    $('.payment-method-two input').prop('disabled', false);
  }
});

function sendInquiry(form) {
  form.find(".form-list-loader").show();
  $(".form-thank-you").hide();
  $(".form-error").hide();
  $.ajax({
    type: 'POST',
    url: form.attr('action'),
    data: new FormData(form[0]),
    async: false,
    processData: false,
    contentType: false
  }).then(function (res) {
    form.find(".form-thank-you").show();
    form.find(".form-list-loader").hide();
  }).catch(function (error) {
    console.log(error);
    form.find(".form-error .inquiry-text p").html(error.responseText);
    form.find(".form-error").show();
    form.find(".form-list-loader").hide();
  });
}

function subscribeToMailee(form) {
  $.ajax({
    type: 'POST',
    url: '?maileeSubmit',
    data: new FormData(form[0]),
    async: false,
    processData: false,
    contentType: false
  }).done(function () {
    //console.log("Success");
  }).fail(function () {
    //console.log("An error occurred");
  });
}

function resizeIframe(height, id) {
  var new_height = parseInt(height);
  $('.lyformsSurvey').css('height', new_height);
}
